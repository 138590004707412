import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { PostItems } from "../components/post"

import Wordmark from "../assets/images/find-your-weirdos.svg"
import HeroMuseum from "../assets/images/home-museum.png"

const IndexPage = () => {
  const { posts } = useStaticQuery(
    graphql`
      fragment PostFields on MarkdownRemark {
        frontmatter {
          title
          title2
          description
          slug
          subtitle
          snippet
          image {
            publicURL
          }
          socialImage {
            publicURL
          }
          imageAlt
        }
      }
      query {
        posts: allMarkdownRemark(
          sort: { fields: [frontmatter___date], order: DESC }
          limit: 1000
        ) {
          edges {
            node {
              ...PostFields
            }
          }
        }
      }
    `
  )

  return (
    <Layout page="home">
      <SEO title="Find Your Weirdos" />

      <section className="hero-top">
        <div className="content-container">
          <h1>
            <img src={Wordmark} className="wordmark" alt="Find Your Weirdos" />
          </h1>
          <p>
            A guide to connecting with the customers who share your niche
            fascinations—and earning their wholehearted patronage
          </p>
          <div className="museum-top"></div>
        </div>
      </section>

      <section className="hero-bottom">
        <div className="content-container">
          <div className="content">
            <p>
              <strong>We want to help you find better ways to connect</strong>{" "}
              with your customers. There is SO much noise out there. Especially
              on the internet. Standing out is exasperating, and keeping
              people’s attention is even harder.
            </p>

            <p>
              <strong>
                This is not about reaching the <em>most</em> people, it’s about
                connecting with the <em>right</em> people
              </strong>
              , your future fans and champions.
            </p>

            <p>
              This collection was developed and curated from September to December, 2020 by the friendly weirdos at{" "}
              <a href="https://andyet.com">&yet</a>. In spring 2021, we asked you to <a href="https://ourweirdosmightbe.yourweirdos.com">meet <strong><em>our</em></strong> weirdos</a>.
            </p>
          </div>
          <div className="museum-bottom">
            <img
              src={HeroMuseum}
              alt="a truly unique museum, filled with all your favorite things"
            />
          </div>
        </div>
      </section>

      <PostItems posts={posts} />
      <div className="pre-footer"></div>
    </Layout>
  )
}

export default IndexPage
